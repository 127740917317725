import { IonButtons, IonContent, IonFooter, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  return (
    <IonPage className="home__page">
      <IonHeader translucent>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons> */}
          <IonTitle className="ion-text-center">{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <ExploreContainer name={name} />
      </IonContent>

      <IonFooter className="home__footer">
        <IonToolbar className="ion-no-padding ion-text-right">
          <div className="footer__action__btn">
            <span className="ion-margin-start ion-margin-top ion-padding-start ion-padding-top">Get <br/> Started</span>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Page;
